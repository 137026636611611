import { Button, Form, FormInstance, Input, message, Space, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import Server from "../server/Server";
import URL from "../server/URL";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import BgImage from '../../src/images/BgImage.jpg';
import React from "react";
import FormItem from "antd/es/form/FormItem";

let timer: string | number | NodeJS.Timeout | null | undefined = null;
const Login = () => {
    const formRef = React.useRef<FormInstance>(null);
    const [loginType, setLoginType] = useState(0);
    const [isSend, setIsSend] = useState(false);
    const [codeSecond, setCodeSecond] = useState(120);

    const getMsgCode = () => {
        formRef.current?.validateFields(['tel'])
            .then((values) => {
                var params = { tel: values.tel };
                Server.Post(URL.User.GetMsgCode, params).then((response: any) => {
                    if (response.isSuccess) {
                        setIsSend(true);
                    }
                });
            })
    }
    useEffect(() => {
        if (isSend) {
            setCodeSecond(120);
            startMsgCode();
        } else {
            stopMsgCode();
        }

    }, [isSend])

    const startMsgCode = () => {
        clearInterval(timer ?? undefined);
        timer = setInterval(() => {
            setCodeSecond((value) => {
                if (value == 0) {
                    setIsSend(false);
                    return 0;
                }
                return (value - 1);
            });
        }, 1000);
    }

    const stopMsgCode = () => {
        clearInterval(timer ?? undefined);
    }

    const items: TabsProps['items'] = [
        {
            key: '0',
            label: `账号登录`,
            children: <>
                <FormItem name={'account'} initialValue={''} rules={[{ required: loginType == 0, message: '请输入账号/手机号码' }]}>
                    <Input style={{ marginBottom: '10px' }} prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="账号/手机号码" />
                </FormItem>
                <FormItem name={'password'} initialValue={''} rules={[{ required: loginType == 0, message: '请输入账户密码' }]}>
                    <Input style={{ marginBottom: '10px' }}
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="密码"
                    />
                </FormItem>
            </>,
        },
        {
            key: '1',
            label: `手机登录`,
            children: <>
                <FormItem name={'tel'} initialValue={''}
                    rules={[{ required: loginType == 1, pattern: new RegExp(/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/, 'g'), message: '请输入11位手机号码' }]}>
                    <Input style={{ marginBottom: '10px' }} placeholder="请输入手机号码" />
                </FormItem>
                <FormItem name={'code'} initialValue={''} rules={[{ required: loginType == 1, message: '请输入验证码' }]}>
                    <Space.Compact style={{ width: '100%', marginBottom: '10px' }}>
                        <Input placeholder="验证码" />
                        <Button style={{ minWidth: '100px' }} type="primary" disabled={isSend} onClick={() => getMsgCode()}>{isSend ? ('(' + codeSecond + 's)') : '获取验证码'}</Button>
                    </Space.Compact>
                </FormItem>
            </>,
        }
    ];

    const onLogin = () => {
        formRef.current?.validateFields()
            .then((values) => {
                var params = { loginType: loginType, account: values.account, password: values.password, tel: values.tel, code: values.code };
                Server.Post(URL.User.UserLogin, params, true).then((response: any) => {
                    if (response.isSuccess) {
                        let etokenKey = process.env.REACT_APP_TOKEN ?? '';
                        localStorage.setItem(etokenKey, response.token);
                        localStorage.setItem("EKnown.UserInfo", JSON.stringify({
                            name: response.name,
                            memberTypeEnum: response.memberTypeEnum,
                            memberType: response.memberType,
                            tel: response.tel
                        }));
                        window.location.href = '/'
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            })
    }

    return <div style={{ background: `url(${BgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100%', height: '100%' }}>
        <div style={{ width: '350px', position: 'absolute', right: '12%', top: '22%' }}>
            <div style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '30px', color: 'white' }}>誉旋科技</span>
            </div>
            <Form ref={formRef} >
                <Tabs style={{ marginBottom: '10px' }} items={items} centered onChange={(e: any) => setLoginType(e)}></Tabs>
                <Button style={{ width: '100%' }} type={'primary'} onClick={onLogin}>登录</Button>
                <div style={{ marginTop: '10px' }}>
                    <span><a onClick={() => window.location.href = '/register'}>立即注册</a></span>
                    <span style={{ float: 'right' }} onClick={() => window.location.href = '/forgetpwd'}><a>忘记密码</a></span>
                </div>
            </Form>
        </div>
    </div>
}
export default Login;