import { Result } from "antd";

const MemberLimit = ()=>{
    return <div>
        <Result
            title="你当前是普通会员，暂时没有权限投保，请联系管理员或上级用户授权绑定"
            extra={
                <div>
                    <span>联系电话：xxxxxxx</span>
                </div>
            }
        />
    </div>
}
export default MemberLimit;