import { Button, Descriptions, Form, FormInstance, Input, message, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

let timer: string | number | NodeJS.Timeout | null | undefined = null;
const Person = () => {
    const formRef = React.useRef<FormInstance>(null);
    const [user, setUser] = useState<any>(null);
    const [editName, setEditName] = useState(false);
    const [editPwd, setEditPwd] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [codeSecond, setCodeSecond] = useState(120);

    useEffect(() => {
        if (isSend) {
            setCodeSecond(120);
            startMsgCode();
        } else {
            stopMsgCode();
        }

    }, [isSend])

    const startMsgCode = () => {
        clearInterval(timer ?? undefined);
        timer = setInterval(() => {
            setCodeSecond((value) => {
                if (value == 0) {
                    setIsSend(false);
                    return 0;
                }
                return (value - 1);
            });
        }, 1000);
    }

    const stopMsgCode = () => {
        clearInterval(timer ?? undefined);
    }

    const getMsgCode = () => {
        var params = { tel: user.tel };
        Server.Post(URL.User.GetMsgCode, params).then((response: any) => {
            if (response.isSuccess) {
                setIsSend(true);
            }
        });
    }
    useEffect(() => {
        Server.Post(URL.User.QueryUser, {}).then((response: any) => {
            if (response.isSuccess) {
                setUser(response);
            }
        });
    }, [])

    const onUpdateName = () => {
        formRef.current?.validateFields(['name'])
            .then((values) => {
                var params = {
                    name: values.name,
                };
                Server.Post(URL.User.UpdateName, params).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('用户名称修改成功');
                        var userInfo = localStorage.getItem('EKnown.UserInfo')
                        if (userInfo) {
                            localStorage.setItem('EKnown.UserInfo', JSON.stringify(Object.assign({}, JSON.parse(userInfo), { name: values.name })));
                        }
                        setUser(Object.assign({}, user, { name: values.name }));
                        setEditName(false)
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            });
    }

    const onUpdatePwd = () => {
        formRef.current?.validateFields(['code', 'password', 'passwordConfirm'])
            .then((values) => {
                var params = {
                    tel: user.tel,
                    code: values.code,
                    password: values.password,
                    passwordConfirm: values.passwordConfirm,
                };
                Server.Post(URL.User.ResetPwd, params).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('用户密码修改成功');
                        setEditPwd(false)
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            });
    }

    return <div style={{ height: '100%' }}>
        <div style={{ width: '100%'}}>
            <Descriptions bordered column={1} labelStyle={{ width: '130px' }}>
                <Descriptions.Item label="会员类型">{user?.memberType}</Descriptions.Item>
                <Descriptions.Item label="用户账户">{user?.account}</Descriptions.Item>
                <Descriptions.Item label="手机号码">{user?.tel}</Descriptions.Item>
                <Descriptions.Item label="用户名称">
                    {editName ?
                        <Form ref={formRef}>
                            <FormItem name={'name'} initialValue={user.name} rules={[{ required: true, message: '请输入用户名' }]}>
                                <Input placeholder="请输入用户名" defaultValue={user.name} />
                            </FormItem>
                        </Form> : <>{user?.name}</>}
                    <span style={{ float: 'right' }}>
                        {editName ? '' : <Button type="primary" size="small" onClick={() => setEditName(true)}>修改</Button>}
                        {editName ? <Space>
                            <Button type="primary" size="small" onClick={() => onUpdateName()}>提交</Button>
                            <Button size="small" onClick={() => setEditName(false)}>取消</Button>
                        </Space> : ''}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label="账户密码">
                    {editPwd ? <Form ref={formRef}>
                        <FormItem name={'code'} initialValue={''} rules={[{ required: true, message: '请输入验证码' }]}>
                            <Space.Compact style={{ width: '100%' }}>
                                <Input placeholder="验证码" />
                                <Button style={{ minWidth: '100px' }} type="primary" disabled={isSend} onClick={() => getMsgCode()}>{isSend ? ('(' + codeSecond + 's)') : '获取验证码'}</Button>
                            </Space.Compact>
                        </FormItem>
                        <FormItem name={'password'} initialValue={''} rules={[{ required: true, message: '请输入密码' }]}>
                            <Input type="password" placeholder="请输入密码" />
                        </FormItem>
                        <FormItem name={'passwordConfirm'} initialValue={''} rules={[{ required: true, message: '请再次输入密码' }]}>
                            <Input type="password" placeholder="请再次输入密码" />
                        </FormItem>
                    </Form> : <>*******</>}
                    <span style={{ float: 'right' }}>
                        {editPwd ? '' : <Button danger type="primary" size="small" onClick={() => setEditPwd(true)}>修改</Button>}
                        {editPwd ? <Space>
                            <Button type="primary" size="small" onClick={() => onUpdatePwd()}>提交</Button>
                            <Button size="small" onClick={() => setEditPwd(false)}>取消</Button>
                        </Space> : ''}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label="注册时间" >{user ? moment(user?.createTime).format('YYYY-MM-DD') : ''}</Descriptions.Item>
            </Descriptions>
        </div>
    </div>
}
export default Person;