import { Button, Form, FormInstance, message, Space } from "antd";
import Input from "antd/lib/input/Input";
import React, { useEffect, useState } from "react";
import Server from "../server/Server";
import URL from "../server/URL";
import FormItem from "antd/es/form/FormItem";

let timer: string | number | NodeJS.Timeout | null | undefined = null;

const ForgetPwd = () => {
    const formRef = React.useRef<FormInstance>(null);
    const [isSend, setIsSend] = useState(false);
    const [codeSecond, setCodeSecond] = useState(120);

    useEffect(() => {
        if (isSend) {
            setCodeSecond(120);
            startMsgCode();
        } else {
            stopMsgCode();
        }

    }, [isSend])

    const startMsgCode = () => {
        clearInterval(timer ?? undefined);
        timer = setInterval(() => {
            setCodeSecond((value) => {
                if (value == 0) {
                    setIsSend(false);
                    return 0;
                }
                return (value - 1);
            });
        }, 1000);
    }

    const stopMsgCode = () => {
        clearInterval(timer ?? undefined);
    }

    const getMsgCode = () => {
        formRef.current?.validateFields(['tel'])
            .then((values) => {
                var params = { tel: values.tel };
                Server.Post(URL.User.GetMsgCode, params).then((response: any) => {
                    if (response.isSuccess) {
                        setIsSend(true);
                    }
                });
            })
    }

    const onSubmit = () => {
        formRef.current?.validateFields()
            .then((values) => {
                var params = {
                    tel: values.tel,
                    code: values.code,
                    password: values.password,
                    passwordConfirm: values.passwordConfirm,
                };
                Server.Post(URL.User.ResetPwd, params).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('密码重置成功');
                        window.location.href = '/login';
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            });
    }

    return <div style={{ backgroundColor: `#282c34`, width: '100%' }}>
        <div style={{ width: '350px', position: 'absolute', top: '25%', left: '40%' }}>
            <div style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '28px' }}>重置密码</span>
            </div>
            <Form ref={formRef} >
                <FormItem name={'tel'} initialValue={''} rules={[{ required: true, pattern: new RegExp(/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/, 'g'), message: '请输入11位手机号码' }]}>
                    <Input style={{ width: '100%' }} placeholder="手机号" />
                </FormItem>
                <FormItem name={'code'} initialValue={''} rules={[{ required: true, message: '请输入验证码' }]}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Input placeholder="验证码" />
                        <Button style={{ minWidth: '100px' }} type="primary" disabled={isSend} onClick={() => getMsgCode()}>{isSend ? ('(' + codeSecond + 's)') : '获取验证码'}</Button>
                    </Space.Compact>
                </FormItem>
                <FormItem name={'password'} initialValue={''} rules={[{ required: true, message: '请输入账户密码' }]}>

                    <Input type="password" style={{ width: '100%' }} placeholder="账户密码" />
                </FormItem>
                <FormItem name={'passwordConfirm'} initialValue={''} rules={[{ required: true, message: '请再次输入账户密码' }]}>
                    <Input type="password" style={{ width: '100%' }} placeholder="账户密码确认" />
                </FormItem>
                <div style={{ marginTop: '10px' }}>
                    <Button style={{ float: 'left', width: '49%' }} type="primary" onClick={() => { onSubmit(); }}>重置密码</Button>
                    <Button style={{ float: 'right', width: '49%' }} onClick={() => { window.location.href = '/login' }}>返回登录</Button>
                </div>
            </Form>
        </div >
    </div >
}
export default ForgetPwd;