import { Button, Input, DatePicker, Table, Pagination, Space, Modal, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import AgentRate from "./AgentRate";
import Recharge from "./Recharge";
const { confirm } = Modal;

const Agent = () => {
    const [page, setPage] = useState({ pageIndex: 1, pageSize: 20 });
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any>([])
    const [searchValue, setSearchValue] = useState('');
    const [userInfo, setUserInfo] = React.useState<any>(null);
    const [isShowRate, setIsShowRate] = useState(false);
    const [isShowRecharge, setIsShowRecharge] = useState(false);
    const [currentMember, setCurrentMember] = useState<any>(null);

    const columns: ColumnsType<any> = [
        { key: '0', title: '会员编号', dataIndex: 'account', align: 'center' },
        { key: '1', title: '手机号', dataIndex: 'tel', align: 'center' },
        { key: '2', title: '会员级别', dataIndex: 'memberType', align: 'center' },
        {
            key: '3', dataIndex: 'createTime', title: '注册时间', align: 'center', render: (text, record) => (
                moment(text).format('YYYY-MM-DD hh:mm:ss')
            )
        },
        { key: '4', title: '上级代理', dataIndex: 'superiorMember', align: 'center' },
        {
            key: '5', title: '操作',
            fixed: 'right',
            align: 'center',
            width: 100,
            render: (record: any) => (
                <Space size={2}>
                    {record.superiorMember == '' ? <Button size="small" onClick={() => onBind(record)}>绑定会员</Button> : ''}
                    {record.superiorMember == userInfo?.tel ? <Button danger size="small" onClick={() => onUnBind(record)}>解绑会员</Button> : ''}
                    {record.superiorMember == userInfo?.tel ? <Button size="small" onClick={() => onSetRate(record)}>设置费率</Button> : ''}
                    {record.superiorMember == userInfo?.tel ? <Button type='primary' size="small" onClick={() => onRecharge(record)}>充值</Button> : ''}
                </Space>
            ),
        }]

    useEffect(() => {
        var userInfo = localStorage.getItem('EKnown.UserInfo');
        if (userInfo) {
            setUserInfo(JSON.parse(userInfo));
        }
    }, [])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Agent.QueryAgentList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    const onBind = (record: any) => {
        var params = { memberID: record.memberID };
        Server.Post(URL.Agent.BindAgent, params).then((response: any) => {
            if (response.isSuccess) {
                loadData();
                message.success('绑定成功')
            }
        });
    }

    const onUnBind = (record: any) => {
        confirm({
            title: '解除绑定',
            icon: <ExclamationCircleFilled />,
            content: '解除绑定会员[' + record.account + ']，解除绑定将清除费率设置信息，确认要解除绑定？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var params = { memberID: record.memberID };
                Server.Post(URL.Agent.UnBindAgent, params).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('解绑成功')
                    }
                });
            },
            onCancel() {
            },
        });
    }

    const onSetRate = (record: any) => {
        setCurrentMember({ memberID: record.memberID, account: record.account })
        setIsShowRate(true);
    }

    const onRecharge = (record: any) => {
        setCurrentMember({ memberID: record.memberID, account: record.account })
        setIsShowRecharge(true);
    }

    useEffect(() => {
        loadData();
    }, [page])

    return <div>
        <div style={{ margin: '5px' }}>
            <Input allowClear onChange={(e) => setSearchValue(e.target.value)} style={{ width: '450px', marginRight: '10px' }} placeholder="请输入会员账号或手机号进行完整匹配查询" />
            <span style={{ fontWeight: 'bold', color: 'red' }}>Tips:默认（无条件）查询所有已绑定会员列表，输入条件精准查询未绑定的会员进行绑定</span>
            <span style={{ float: 'right' }}>
                <Button type='primary' onClick={loadData}>查询</Button>
            </span>
        </div>
        <div style={{ margin: '5px', border: '1px solid #d9d9d9' }}>
            <Table size="small" scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: true,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
        <AgentRate isOpen={isShowRate} member={currentMember} setIsOpen={(value: any) => setIsShowRate(value)} />
        <Recharge isOpen={isShowRecharge} member={currentMember} setIsOpen={(value: any) => setIsShowRecharge(value)} />
    </div>
}
export default Agent;