import React from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { Link, Route, Routes } from 'react-router-dom';
import Person from './person/Person';
import '../../styles/main.css';
import Info from './info/Info';

const { Header, Content, Footer, Sider } = Layout;

const items1: MenuProps['items'] = ['1', '2', '3'].map((key) => ({
    key,
    label: `nav ${key}`,
}));

const items2: MenuProps['items'] = [
    {
        key: '0',
        label: `个人设置`,
        icon: React.createElement(LaptopOutlined),
        children: [
            {
                key: '1',
                label: <Link to='/admin/setting/person'>用户信息</Link>,
            },
            {
                key: '2',
                label: <Link to='/admin/setting/info'>投/被保人</Link>
            }
        ]
    }
];
const Setting = () => {

    return <div style={{ minHeight: '100%' }} className="layout">
        <Layout style={{ height: '100%' }}>
            <Sider style={{ background: '' }} width={220}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['0']}
                    style={{
                        minHeight: '100%'
                    }}
                    items={items2}
                />
            </Sider>
            <Content className="site-layout" style={{
                minHeight: '100%'
            }}>
                <div className="site-layout-background" style={{
                    padding: '20px 20px 20px 20px',
                    minHeight: '100%'
                }}>
                    <Routes>
                        <Route path='/' element={<Person />} />
                        <Route path='/person' element={<Person />} />
                        <Route path='/info' element={<Info />} />
                    </Routes>
                </div>
            </Content>
        </Layout>
    </div>
}
export default Setting;