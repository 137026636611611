import { Button, Input, DatePicker, Table, Pagination } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";
const { RangePicker } = DatePicker;

const columns: ColumnsType<any> = [
    { key: 'insuranceNO', dataIndex: 'insuranceNO', title: '保单号', align: 'center' },
    {
        key: 'payTime', dataIndex: 'payTime', title: '支付时间', align: 'center', render: (text, record) => (
            moment(text).format('YYYY-MM-DD hh:mm:ss')
        )
    },
    { key: 'sourceMember', dataIndex: 'sourceMember', title: '来源用户', align: 'center' },
    { key: 'amount', dataIndex: 'amount', title: '收益金额', align: 'center' }]

const Income = () => {
    const [page, setPage] = useState({ pageIndex: 1, pageSize: 20 });
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any>([])
    const [searchValue, setSearchValue] = useState('');
    const [timeValue, setTimeValue] = useState<any>([null, null]);
    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
    }, [])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            startTime: timeValue && timeValue[0] ? moment(timeValue[0]).format(dateFormat) : null,
            endTime: timeValue && timeValue[1] ? moment(timeValue[1]).format(dateFormat) : null,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Income.QueryIncomeList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    useEffect(() => {
        loadData();
    }, [page])

    return <div>
        <div style={{ margin: '5px' }}>
            <Input allowClear style={{ width: '450px', marginRight: '10px' }} onChange={(e) => setSearchValue(e.target.value)} placeholder="请输入来源用户" />
            <RangePicker allowClear format={dateFormat} onChange={(value) => setTimeValue(value)} style={{ marginRight: '10px' }} placeholder={['投保时间', '至']} />
            <span style={{ float: 'right' }}>
                <Button type='primary' onClick={loadData}>查询</Button>
            </span>
        </div>
        <div style={{ margin: '5px', border: '1px solid #d9d9d9' }}>
            <Table size="small" scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: true,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
    </div>
}
export default Income;