import { Badge, Button, DatePicker, Typography, Input, message, Modal, Pagination, Radio, Space, Steps, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from 'moment';
import 'moment/locale/zh-cn';
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { RangeValue } from "rc-picker/lib/interface";
import axios from "axios";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const Order = () => {
    const [page, setPage] = useState({ pageIndex: 1, pageSize: 20 });
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any>([])
    const [searchValue, setSearchValue] = useState('');
    const [timeValue, setTimeValue] = useState<any>([null, null]);
    const [state, setState] = useState<any>('-1');
    const dateFormat = 'YYYY-MM-DD';

    const columns: ColumnsType<any> = [
        {
            key: 'orderNO', title: '投保单号', dataIndex: 'orderNO',
            align: 'center', fixed: 'left', width: 100, ellipsis: true,
            render: (text, record) => <a onClick={() => { window.location.href = '/admin/insure?mode=2&&oid=' + record.orderID }}>{text}</a>,
        },
        {
            key: 'state', title: '保单状态', dataIndex: 'state',
            align: 'center', fixed: 'left', width: 80, ellipsis: true, render: (text, record) => (
                record.stateEnum == 0 ? <Badge status={'default'} text={text} /> :
                    (record.stateEnum == 1 ? <Badge status={'warning'} text={text} /> :
                        (record.stateEnum == 2 ? <Badge status={'processing'} text={text} /> :
                            (record.stateEnum == 3 ? <Badge status={'success'} text={text} /> :
                                (record.stateEnum == 4 ? <Badge status={'warning'} text={text} /> :
                                    (record.stateEnum == 5 ? <Badge status={'error'} text={text} /> :
                                        (record.stateEnum == 6 ? <Badge status={'success'} text={text} /> :
                                            (record.stateEnum == 7 ? <Badge status={'success'} text={text} /> :
                                                (record.stateEnum == 8 ? <Badge status={'default'} text={text} /> : ''))))))))
            )

        }, {
            key: 'insuranceNO', title: '保单号', dataIndex: 'insuranceNO',
            align: 'center', fixed: 'left', width: 150, ellipsis: true
        }, {
            key: 'channel', title: '保单来源', dataIndex: 'channel',
            align: 'center', width: 80, ellipsis: true
        },
        {
            key: 'insureTime', title: '投保时间', dataIndex: 'insureTime',
            align: 'center', width: 150, render: (text, record) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        },
        {
            key: 'insureBy', title: '投保人', dataIndex: 'insureBy',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: 'insuredBy', title: '被保人', dataIndex: 'insuredBy',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: 'departureTime', title: '起运时间', dataIndex: 'departureTime',
            align: 'center', width: 100, ellipsis: true, render: (text, record) => (
                moment(text).format('YYYY-MM-DD')
            )
        },
        {
            key: 'startAddress', title: '起运地', dataIndex: 'startAddress',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: 'destAddress', title: '目的地', dataIndex: 'destAddress',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: '1', title: '公司|险种|类型', width: 200,
            align: 'center', render: (text, record) => (
                <div>
                    {record.companyName + '|' + record.productType + '|' + record.type}
                </div>
            )
        },
        {
            key: 'partValue', title: '货值', dataIndex: 'partValue', width: 80,
            align: 'center', render: (text, record) => (
                text + '万元'
            )
        },
        {
            key: 'amount', title: '保费', dataIndex: 'amount', width: 100,
            align: 'center', render: (text, record) => (
                text + '元'
            )
        },
        {
            key: 'payAmount', title: '已支付金额', dataIndex: 'payAmount', width: 100,
            align: 'center', render: (text, record) => (
                text + '元'
            )
        },
        {
            key: '1', title: '操作',
            fixed: 'right',
            align: 'center',
            render: (record: any) => (
                <Space size={2}>
                    <Button size="small" type="primary" onClick={() => { window.location.href = '/admin/insure?mode=2&&oid=' + record.orderID }}>查看</Button>
                    {record.stateEnum == 0 || (record.stateEnum <= 3 && record?.isAllowUpdate) ? <Button size="small" onClick={() => { window.location.href = '/admin/insure?mode=1&&oid=' + record.orderID }}>修改</Button> : ''}
                    {record.stateEnum == 0 ? <Button type='primary' onClick={() => { showDeleteConfirm(record) }} danger size="small">删除</Button> : ''}
                    {record.stateEnum == 0 ? <Button type='primary' onClick={() => { window.location.href = '/admin/insure?mode=2&&oid=' + record.orderID }} size="small">支付</Button> : ''}
                    {record.stateEnum >= 3 && record.insuranceUrl && record.insuranceUrl != '' ? <Button type='dashed' onClick={() => {
                        if (record.isOpenDown) {
                            window.open(record.insuranceUrl)
                        } else {
                            downloadFile(record.orderID, record.insuranceNO + ".pdf");
                        }
                    }} size="small">下载</Button> : ''}
                    <Button key="buy" size="small" danger onClick={() => {
                        var url = '/admin/insure?oid=' + record.orderID + '&&isCopy=true';
                        window.location.href = url;
                    }}>再来一单</Button>
                </Space>
            ),
        }]

    const downloadFile = (orderID: string, filename: string) => {
        var reuqest = {
            orderID: orderID
        };
        axios.post(URL.Order.DownOrder, reuqest, {
            'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
        }).then(function (response) {
            const blob = new Blob([response.data]);
            const fileName = filename;
            const linkNode = document.createElement('a');
            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = 'none';
            linkNode.href = global.URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click();  //模拟在按钮上的一次鼠标单击
            global.URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            startTime: timeValue && timeValue[0] ? moment(timeValue[0]).format(dateFormat) : null,
            endTime: timeValue && timeValue[1] ? moment(timeValue[1]).format(dateFormat) : null,
            state: state,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Order.QueryOrderList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    useEffect(() => {
        loadData();
    }, [page])

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除保单',
            icon: <ExclamationCircleFilled />,
            content: '投保单号[' + record.orderNO + ']，确认要删除保单，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { orderID: record.orderID }
                Server.Post(URL.Order.DeleteOrder, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };
    return <div style={{ height: 'auto' }}>
        <div style={{ margin: '5px' }}>
            <Input allowClear style={{ width: '450px', marginRight: '10px' }}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="请输入保单号、目的地、起运地、投保人、被保人、运输单号" />
            <RangePicker allowClear style={{ marginRight: '10px' }} format={dateFormat} onChange={(value) => { setTimeValue(value) }} placeholder={['投保时间', '至']} />
            <span style={{ float: 'right' }}>
                <Button style={{ marginRight: '10px' }} type='primary' onClick={loadData}>查询</Button>
                <Button style={{ marginRight: '10px' }} danger onClick={() => { window.location.href = '/admin/insure'; }}>立即投保</Button>
                {/* <Button >导出保单</Button> */}
            </span>
        </div>
        <div style={{ margin: '5px' }}>
            <Radio.Group defaultValue={"-1"} value={state} buttonStyle="solid"
                onChange={(e) => {
                    setState(e.target.value);
                    setPage({ pageIndex: 1, pageSize: page.pageSize })
                }}>
                <Radio.Button value="-1">全部</Radio.Button>
                <Radio.Button value="0">未支付</Radio.Button>
                <Radio.Button value="1">已支付</Radio.Button>
                <Radio.Button value="2">出单中</Radio.Button>
                <Radio.Button value="3">已出单</Radio.Button>
                <Radio.Button value="4">修改中</Radio.Button>
                <Radio.Button value="5">已修改</Radio.Button>
                <Radio.Button value="6">退保中</Radio.Button>
                <Radio.Button value="7">已退保</Radio.Button>
                <Radio.Button value="8">已完成</Radio.Button>
                <Radio.Button value="9">已失败</Radio.Button>
                <Radio.Button value="10">已取消</Radio.Button>
            </Radio.Group>
        </div>
        <div style={{ margin: '5px', border: '1px solid #d9d9d9' }}>
            <Table size="small" scroll={{ x: 'max-content' }}
                columns={columns} dataSource={dataSource}
                pagination={{
                    position: ['bottomRight'],
                    style: { marginRight: '10px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} />
        </div>
    </div>
}
export default Order;

