import { Button, Input, message, Modal } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";

const InsureInfo = (props: any) => {
    const [page, setPage] = useState({ pageIndex: 1, pageSize: 10 });
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any>([])
    const [searchValue, setSearchValue] = useState('');
    const { ...rest } = props;
    const [title, setTitle] = useState('');
    const [selectedRowKeys, setSelectRowKeys] = useState<any>([]);
    const [selectRow, setSelectRow] = useState<any>(null);

    const columns: ColumnsType<any> = [
        { key: '0', title: '类型', dataIndex: 'type', align: 'center' },
        { key: '1', title: '个人/企业', dataIndex: 'name', align: 'center' },
        { key: '2', title: '身份证/信用代码', dataIndex: 'id', align: 'center' },
        { key: '4', title: '联系电话', dataIndex: 'tel', align: 'center' }]

    useEffect(() => {
        if (rest.isOpen) {
            if (rest.selectType == 0) {
                switch (rest.insureType.value) {
                    case 0:
                    case 2:
                        setTitle('选择个人');
                        break;
                    case 1:
                    case 3:
                        setTitle('选择企业');
                        break;
                }
            } else {
                switch (rest.insureType.value) {
                    case 0:
                    case 3:
                        setTitle('选择个人');
                        break;
                    case 1:
                    case 2:
                        setTitle('选择企业');
                        break;
                }
            }
            loadData();
        }
    }, [rest.isOpen])

    const handleCancel = () => {
        rest.setIsOpen(false);
    }
    const handleOK = () => {
        if (selectRow == null) {
            message.warn('请选择数据行');
            return;
        }
        rest.setValue(selectRow);
        rest.setIsOpen(false);
    }

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize,
            typeEnum: rest.insureType.value == 0 || rest.insureType.value == 2 ? 0 : 1
        };
        Server.Post(URL.InsureInfo.QueryInsureInfoList, params).then((response: any) => {
            if (response.isSuccess) {
                if (response.data && response.data.length > 0) {
                    setSelectRowKeys([response.data[0].key])
                    setSelectRow(response.data[0]);
                }
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectRowKeys(selectedRowKeys);
            setSelectRow(selectedRows[0]);
        }
    };

    useEffect(() => {
        if (rest.isOpen) {
            loadData();
        }
    }, [page])

    return <Modal
        title={title}
        width={1000}
        visible={rest?.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
            <>
                <Button onClick={handleCancel} danger>关闭窗口</Button>
                <Button onClick={handleOK} type={'primary'}>确定</Button>
            </>
        }>
        <div>
            <div style={{ margin: '5px' }}>
                <Input allowClear onChange={(e) => setSearchValue(e.target.value)} style={{ width: '450px', marginRight: '10px' }}
                    placeholder="请输入名称/ID/电话模糊匹配查询" />
                <span style={{ fontWeight: 'bold', color: 'red' }}>Tips:维护信息，请至 个人设置=》投/被保人中操作</span>
                <span style={{ float: 'right' }}>
                    <Button type='primary' onClick={loadData}>查询</Button>
                </span>
            </div>
            <div style={{ margin: '5px', border: '1px solid #d9d9d9' }}>
                <Table size="small"
                    rowSelection={{
                        type: 'radio',
                        selectedRowKeys: selectedRowKeys,
                        ...rowSelection,
                    }}
                    style={{ overflowY: 'auto', maxHeight: '492px' }}
                    scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                        position: ['bottomRight'],
                        style: { marginRight: '10px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        showTotal: ((total: number) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 10,
                        pageSize: page.pageSize,
                        pageSizeOptions: [10],
                        onShowSizeChange: ((page: number, pageSize: number) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page: number, pageSize: number) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }} />
            </div>
        </div>
    </Modal>
}
export default InsureInfo;