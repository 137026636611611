import { Button, Form, FormInstance, Input, message, Modal, Select, Space, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const InfoEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [type, setType] = useState<any>({ value: '1', label: '企业' });

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getInsureInfo();
                    break;
                case 2:
                    getInsureInfo();
                    break;
            }

        }
    }, [rest.isOpen])

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue(record);
        }
        else {
            formRef.current?.resetFields();
        }
    }

    const getInsureInfo = () => {
        if (rest.insureInfoID) {
            var params = {
                insureInfoID: rest.insureInfoID
            };
            Server.Post(URL.InsureInfo.QueryInsureInfo, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                    setType({ value: response.typeEnum, label: response.type })
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = {
                insureInfoID: values.insureInfoID,
                typeEnum: type.value,
                type: type.label,
                name: values.name,
                id: values.id,
                tel: values.tel,
                remark: values.remark
            };
            Server.Post(URL.InsureInfo.SaveInsureInfo, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        }));
    }

    return <Modal title={(rest.viewMode == 0 ? '新增' : '编辑') + "投/被保人"}
        width={500} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 10 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}>
            <Form.Item hidden label={'投/保被保人ID'} name={'insureInfoID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Form.Item label={'类型'} name={'type'} initialValue={type?.value} rules={[{ required: true, message: '请选择类型' }]}>
                <Select
                    defaultValue={type.value}
                    value={type.value}
                    labelInValue
                    onSelect={(e) => { setType(e); }}
                    style={{ width: '100%' }}
                    options={[
                        { value: '0', label: '个人' },
                        { value: '1', label: '企业' }
                    ]} />
            </Form.Item>
            <Form.Item label={'名称'} name={'name'} initialValue={''} rules={[{ required: true, message: '请输入名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item label={type?.value == '0' ? '身份证号' : '信用代码'} name={'id'} initialValue={''} rules={[{ required: true, message: '请输入18位' + (type?.value == '0' ? '身份证号' : '信用代码') }]}>
                <Input />
            </Form.Item>
            <Form.Item label={'电话'} name={'tel'} initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item label={'备注'} name={'remark'} initialValue={''}>
                <Input />
            </Form.Item>
        </Form>
    </Modal >
}
export default InfoEdit;