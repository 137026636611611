let BaseUrl = '/api/';
let UserUrl = BaseUrl + "User/";
let ProductUrl = BaseUrl + "Product/";
let OrderUrl = BaseUrl + "Order/";
let BalanceUrl = BaseUrl + "Balance/";
let IncomeUrl = BaseUrl + "Income/";
let AgentUrl = BaseUrl + "Agent/";
let InsureInfoUrl = BaseUrl + "InsureInfo/";

const URL = {
    User: {
        UserLogin: UserUrl + 'UserLogin',
        Register: UserUrl + 'Register',
        ResetPwd: UserUrl + 'ResetPwd',
        GetMsgCode: UserUrl + 'GetMsgCode',
        QueryUser: UserUrl + 'QueryUser',
        UpdateName: UserUrl + 'UpdateName',
    },
    Product: {
        QueryProductList: ProductUrl + 'QueryProductList',
        QueryCompanyAddition: ProductUrl + 'QueryCompanyAddition',
        QueryProductAddition: ProductUrl + 'QueryProductAddition',
        GetCompanyList: ProductUrl + 'GetProductCompanyList'
    },
    Order: {
        InsureOrder: OrderUrl + 'InsureOrder',
        QueryOrder: OrderUrl + 'QueryOrder',
        QueryOrderList: OrderUrl + 'QueryOrderList',
        SaveOrder: OrderUrl + 'SaveOrder',
        DeleteOrder: OrderUrl + 'DeleteOrder',
        PayOrder: OrderUrl + 'PayOrder',
        CancelOrder: OrderUrl + 'CancelOrder',
        DownOrder: OrderUrl + 'DownOrder',
    },
    Balance: {
        QueryBalance: BalanceUrl + 'QueryBalance',
        QueryRechargeList: BalanceUrl + 'QueryRechargeList',
        QueryConsumptionList: BalanceUrl + 'QueryConsumptionList'
    },
    Income: {
        QueryIncomeList: IncomeUrl + 'QueryIncomeList'
    },
    Agent: {
        QueryAgentList: AgentUrl + 'QueryAgentList',
        BindAgent: AgentUrl + 'BindAgent',
        UnBindAgent: AgentUrl + 'UnBindAgent',
        QueryAgentRateList: AgentUrl + 'QueryAgentRateList',
        SaveAgentRate: AgentUrl + 'SaveAgentRate',
        AgentRecharge: AgentUrl + 'AgentRecharge',
    },
    InsureInfo: {
        QueryInsureInfoList: InsureInfoUrl + 'QueryInsureInfoList',
        QueryInsureInfo: InsureInfoUrl + 'QueryInsureInfo',
        SaveInsureInfo: InsureInfoUrl + 'SaveInsureInfo',
        DeleteInsureInfo : InsureInfoUrl + 'DeleteInsureInfo'
    }
}

export default URL;