import { Typography, Badge, Button, Checkbox, Col, DatePicker, Descriptions, Divider, Drawer, Form, FormInstance, Input, InputNumber, message, Modal, notification, Popover, Radio, Result, Row, Select, Space, Steps } from "antd";
import FormItem from "antd/es/form/FormItem";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";
import EUtils from "../../utils/EUtils";
import { ExclamationCircleFilled, ContactsOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import InsureInfo from "./InsureInfo";
import { userInfo } from "os";
import axios from "axios";
const { confirm } = Modal;
const { Search } = Input;

const Insure = () => {
    const formRef = React.useRef<FormInstance>(null);
    const [userInfo, setUserInfo] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<any>({});
    const [isRead, setIsRead] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [data, setData] = useState<any>(null);
    const [scopeData, setScopeData] = useState<any[]>([]);
    const [curentScope, setCurrentScope] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any[]>([]);
    const [curentCompany, setCurrentCompany] = useState<any>(null);
    const [productData, setProductData] = useState<any[]>([]);
    const [curentProduct, setCurrentProduct] = useState<any>(null);
    const [typeData, setTypeData] = useState<any[]>([]);
    const [curentType, setCurrentType] = useState<any>(null);
    const [insureType, setInsureType] = useState<any>({ value: 1, label: '企业投保企业' });
    const [transportMode, setTransportMode] = useState<any>({ value: '0', label: '公路' });
    const [isSame, setIsSame] = useState<boolean>(true);
    const [mode, setMode] = useState(0);
    const [validRecord, setValidRecord] = useState<any>({ departureTime: true, partValue: true });
    const [orderID, setOrderID] = useState(null);
    const [isCopy, setIsCopy] = useState<any>(false);
    const [order, setOrder] = useState<any>(null);
    const [balance, setBalance] = useState(0);
    const [notice, setNotice] = useState('');//投保申明
    const [disclaimer, setDisclaimer] = useState('');//投保须知
    const [clauses, setClauses] = useState('');//保险条款
    const [openSelect, setOpenSelect] = useState(false);
    const [selectType, setSelectType] = useState<number | null>(null);

    useEffect(() => {
        var params = EUtils.getUrlParams();
        if (params) {
            Promise.resolve().then(() => {
                setMode(params.mode ?? 0);
                if (params.oid) {
                    setOrderID(params.oid);
                }
                if (params.isCopy) {
                    setIsCopy(params.isCopy);
                }
            }).then(() => {
                loadProductData();
                if (params.mode == 2) {
                    setCurrentStep(1);
                    if (params.oid) {
                        loadOrder(params.oid);
                    }
                }
            })
        }
    }, [])

    const loadProductData = () => {
        Server.Post(URL.Product.QueryProductList, {}, false)
            .then((response: any) => {
                if (response.isSuccess) {
                    setData(response.data);
                }
            })
    }

    useEffect(() => {
        if (data) {
            if (data.scopes && data.scopes.length > 0) {
                var result = data.scopes;
                setScopeData(data.scopes);
                if (!curentScope) {
                    if (result && result.length > 0) {
                        setCurrentScope(result[0])
                    }
                }
            }
            if (orderID) {
                loadOrder(orderID);
            }
        }
    }, [data])

    useEffect(() => {
        if (curentScope) {
            var result = data?.companys[curentScope.key] ?? [];
            Promise.resolve().then(() => {
                setCompanyData(result);
            }).then(() => {
                if (mode == 0 && result && result.length > 0) {
                    setCurrentCompany(result[0])
                }
            })
        }
    }, [curentScope])

    useEffect(() => {
        if (curentCompany) {
            var result = data?.products[curentCompany.key] ?? [];
            Promise.resolve().then(() => {
                setProductData(result);
            }).then(() => {
                if (mode == 0 && result && result.length > 0) {
                    setCurrentProduct(result[0])
                }
            })
        }
    }, [curentCompany])

    useEffect(() => {
        if (curentProduct) {
            var result = data?.types[curentProduct.key] ?? [];
            Promise.resolve().then(() => {
                setTypeData(result);
            }).then(() => {
                if (mode == 0 && result && result.length > 0) {
                    setCurrentType(result[0])
                }
            })
        }
    }, [curentProduct])

    useEffect(() => {
        if (curentType) {
            //计算保费;
            var rate = curentType.rate;
            formRef.current?.setFieldValue('rate', rate);

            caculateAmount();

            var params = {
                productID: curentType.productID
            }
            Server.Post(URL.Product.QueryProductAddition, params, false)
                .then((response: any) => {
                    if (response.isSuccess) {
                        setClauses(response.clauses ?? '');
                        setNotice(response.notice ?? '');
                        setDisclaimer(response.disclaimer ?? '');
                    }
                })
        } else {
            setClauses('');
        }
    }, [curentType])

    const caculateAmount = () => {
        var rate = curentType?.rate;
        var partValue = formRef.current?.getFieldValue('partValue');
        var amount = ((partValue ?? 1) * rate);
        if (curentType.minAmount && amount < curentType.minAmount) {
            amount = curentType.minAmount;
        }
        formRef.current?.setFieldValue('amount', amount ? amount.toFixed(2) : 0);
    }

    const onClose = () => {
        setOpen(false);
    };
    const onReadChange = (e: CheckboxChangeEvent) => {
        setContent({ title: '保险条款', info: clauses })
        setOpen(e.target.checked)
        setIsRead(e.target.checked);
    };

    const onSubumit = () => {
        var values = formRef.current?.getFieldsValue();
        if (validate()) {
            //校验通过，调用接口保存；
            var params = {
                orderID: orderID,
                scopeEnum: curentScope.id,
                scope: curentScope.name,
                companyID: curentCompany.id,
                companyName: curentCompany.name,
                productTypeEnum: curentProduct.id,
                productType: curentProduct.name,
                typeID: curentType.id,
                type: curentType.name,
                productID: curentType.productID,
                productName: curentType.productName,
                vendorProductExt: curentType.vendorProductExt ?? '',
                insureTypeEnum: insureType.value,
                insureType: insureType.label,
                isSame: isSame,
                insureBy: values.insureBy,
                insureID: values.insureID,
                insureTel: values.insureTel,
                insuredBy: values.insuredBy,
                insuredID: values.insuredID,
                insuredTel: values.insuredTel,
                departureTime: moment(values.departureTime),
                startAddress: values.startAddress,
                destAddress: values.destAddress,
                transitAddress: values.transitAddress,
                transportModeEnum: transportMode.value,
                transportMode: transportMode.label,
                partName: values.partName,
                package: values.package.label,
                eaQty: values.eaQty,
                truckNO: values.truckNO,
                transportNO: values.transportNO ?? '',
                partValue: values.partValue,
                rate: values.rate,
                amount: values.amount,
            };
            Server.Post(URL.Order.InsureOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    setOrderID(response.orderID);
                    setIsCopy(false);
                    setCurrentStep(1);
                    loadOrder(response.orderID);
                    notification.open({
                        placement: 'topRight',
                        message: '填写保单',
                        description:
                            '保单提交成功，请完成支付确认'
                    });
                } else {
                    message.warning(response.returnMsg);
                }
            })
        }
    }

    const onUpdate = () => {
        var values = formRef.current?.getFieldsValue();

        if (validate()) {
            //校验通过，调用接口保存；
            var params = {
                orderID: orderID,
                insuredBy: values.insuredBy,
                insuredID: values.insuredID,
                insuredTel: values.insuredTel,
                departureTime: moment(values.departureTime),
                startAddress: values.startAddress,
                destAddress: values.destAddress,
                transitAddress: values.transitAddress,
                transportModeEnum: transportMode.value,
                transportMode: transportMode.label,
                partName: values.partName,
                package: values.package.label,
                eaQty: values.eaQty,
                truckNO: values.truckNO,
                transportNO: values.transportNO ?? '',
            };
            Server.Post(URL.Order.SaveOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    setOrder(Object.assign({}, Object.assign({}, order, params), {
                        stateEnum: response.stateEnum,
                        state: response.state,
                    }));
                    setCurrentStep(1);
                    notification.open({
                        placement: 'topRight',
                        message: '修改保单',
                        description:
                            order?.stateEnum >= 1 ?
                                '保单修改成功，修改的保单信息已提交保司' :
                                '保单修改成功，请完成支付确认后执行投保'

                    });
                } else {
                    message.warning(response.returnMsg);
                }
            })
        }
    }

    const loadOrder = (oid: any) => {
        //查询保单
        var param = { orderID: oid };
        Server.Post(URL.Order.QueryOrder, param).then((response: any) => {
            if (response.isSuccess) {
                setOrder(Object.assign({}, response, {
                    departureTime: isCopy ? moment() : moment(response.departureTime),
                    transportNO: isCopy ? '' : response.transportNO
                }));
                Promise.resolve().then(() => {
                    setCurrentScope({ key: response.scopeEnum, id: response.scopeEnum, name: response.scope });
                }).then(() => {
                    setCurrentCompany({ key: response.companyID, id: response.companyID, name: response.companyName });
                }).then(() => {
                    setCurrentProduct({ key: response.companyID + response.productTypeEnum, id: response.productTypeEnum, name: response.productName });
                }).then(() => {
                    setCurrentType({
                        key: response.scopeEnum,
                        id: response.typeEnum,
                        name: response.type,
                        rate: response.rate,
                        productID: response.productID,
                        productName: response.productName,
                        minAmount: response.minAmount ?? 15,
                        isAllowUpdate: response.isAllowUpdate,
                        isIDRequired: response.isIDRequired
                    });
                })
                setInsureType({ value: response.insureTypeEnum, label: response.insureType });
                setTransportMode({ value: response.transportModeEnum, label: response.transportMode });
                setIsSame(response.isSame);
                formRef.current?.setFieldsValue(Object.assign({}, response, {
                    departureTime: isCopy ? moment() : moment(response.departureTime),
                    package: { value: response.package, label: response.package }
                }));
                setValidRecord({
                    insureBy: true,
                    insureID: true,
                    insureTel: true,
                    insuredBy: true,
                    insuredID: true,
                    insuredTel: true,
                    departureTime: true,
                    startAddress: true,
                    destAddress: true,
                    transitAddress: true,
                    partName: true,
                    package: true,
                    eaQty: true,
                    truckNO: true,
                    partValue: true
                });
            }
        });
    }

    useEffect(() => {
        if (currentStep == 0) {
            var userInfo = localStorage.getItem('EKnown.UserInfo');
            if (userInfo) {
                formRef.current?.setFieldValue('insureTel', JSON.parse(userInfo).tel);
                setValidRecord(Object.assign({}, validRecord, {
                    insureTel: true
                }));
                setUserInfo(JSON.parse(userInfo));
            }
        }
        else if (currentStep == 1) {
            queryBalance();
        }
    }, [currentStep])

    const queryBalance = () => {
        Server.Post(URL.Balance.QueryBalance, {}).then((response: any) => {
            if (response.isSuccess) {
                setBalance(response.balance);
            }
        })
    }

    const validate = () => {
        if (!validRecord.insureBy) {
            message.warn('投保人不能为空，请检查');
            return false;
        }
        if (!validRecord.insureID) {
            message.warn('投保' + (insureType.value == 1 || insureType.value == 3 ? '信用代码' : '身份证号') + '为18位字符，请检查');
            return false;
        }
        if (!validRecord.insureTel) {
            message.warn('投保手机号码为11位数字，请检查');
            return false;
        }
        if (!validRecord.insuredBy) {
            message.warn('被保人不能为空，请检查');
            return false;
        }
        if (!validRecord.insuredID) {
            message.warn('被保' + (insureType.value == 1 || insureType.value == 2 ? '信用代码' : '身份证号') + '为18位字符，请检查');
            return false;
        }
        if (!validRecord.departureTime) {
            message.warn('起运日期不能为空，请检查');
            return false;
        }
        if (!validRecord.startAddress) {
            message.warn('起运地不能为空，请检查');
            return false;
        }
        if (!validRecord.destAddress) {
            message.warn('目的地不能为空，请检查');
            return false;
        }
        if (!validRecord.partName) {
            message.warn('货物品名不能为空，请检查');
            return false;
        }
        if (!validRecord.package) {
            message.warn('货物包装不能为空，请检查');
            return false;
        }
        if (!validRecord.eaQty) {
            message.warn('货物数量不能为空，请检查');
            return false;
        }
        if (!validRecord.truckNO) {
            message.warn('车牌号不能为空，请检查');
            return false;
        }
        return true;
    }

    const onPay = () => {
        confirm({
            title: '支付确认',
            icon: <ExclamationCircleFilled />,
            content: '投保单号[' + order.orderNO + ']，确认要从账户支付保费' + order.amount + '元?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                if (balance == 0) {
                    queryBalance();
                }
                var params = { orderID: order.orderID, payAmount: order.amount };
                Server.Post(URL.Order.PayOrder, params).then((response: any) => {
                    if (response.isSuccess) {
                        setOrder(Object.assign({}, order, {
                            insuranceNO: response.insuranceNO,
                            stateEnum: response.stateEnum,
                            state: response.state,
                            insureTime: response.insureTime
                        }));
                        setCurrentStep(2)
                        notification.open({
                            placement: 'topRight',
                            message: '支付确认',
                            description:
                                '保单支付成功，保司已出单，保单号：' + response.insuranceNO + ',已完成投保'
                        });
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    }
    const downloadFile = (orderID: string, filename: string) => {
        var reuqest = {
            orderID: orderID
        };
        axios.post(URL.Order.DownOrder, reuqest, {
            'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
        }).then(function (response) {
            const blob = new Blob([response.data]);
            const fileName = filename;
            const linkNode = document.createElement('a');
            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = 'none';
            linkNode.href = global.URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click();  //模拟在按钮上的一次鼠标单击
            global.URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
        }).catch(function (error) {
            console.log(error);
        });
    }

    return <div>
        <div style={{ padding: '5px 250px' }}>
            <Steps size="small"
                current={currentStep}
                items={[
                    {
                        title: mode == 0 ? '填写保单' : '修改保单',
                    },
                    {
                        title: '支付确认',
                    },
                    {
                        title: '投保完成',
                    },
                ]}
            />
        </div>
        <div style={{ display: currentStep == 0 ? 'block' : 'none' }}>
            <div style={{ padding: '5px 0', borderBottom: '1px dashed #d9d9d9' }}>
                <span style={{ fontWeight: 'bold' }}>保险范围：</span>
                {
                    (scopeData && scopeData.length > 0) ?
                        <Radio.Group disabled={mode != 0} value={curentScope?.id} onChange={(e) => {
                            var item = scopeData.filter(d => d.id == e.target.value)[0];
                            setCurrentScope(item)
                        }
                        } buttonStyle="solid">
                            {
                                scopeData.map((item: any) => {
                                    return <Radio.Button key={item.id} value={item.id}>{item.name}</Radio.Button>;
                                })
                            }
                        </Radio.Group> : <span style={{ lineHeight: '32px' }}></span>
                }
            </div>
            <div style={{ padding: '5px 0', borderBottom: '1px dashed #d9d9d9' }}>
                <span style={{ fontWeight: 'bold' }}>保险公司：</span>
                {
                    (companyData && companyData.length > 0) ?
                        <Radio.Group disabled={mode != 0} value={curentCompany?.id} onChange={(e) => {
                            var item = companyData.filter(d => d.id == e.target.value)[0];
                            setCurrentCompany(item)
                        }
                        } buttonStyle="solid">
                            {
                                companyData.map((item: any) => {
                                    return <Radio.Button key={item.id} value={item.id}>{item.name}</Radio.Button>;
                                })
                            }
                        </Radio.Group> : <span style={{ lineHeight: '32px' }}></span>
                }
            </div>
            <div style={{ padding: '5px 0', borderBottom: '1px dashed #d9d9d9' }}>
                <span style={{ fontWeight: 'bold' }}>保险产品：</span>
                {
                    (productData && productData.length > 0) ?
                        <Radio.Group disabled={mode != 0} value={curentProduct?.id} onChange={(e) => {
                            var item = productData.filter(d => d.id == e.target.value)[0];
                            setCurrentProduct(item)
                        }
                        } buttonStyle="solid">{
                                productData.map((item: any) => {
                                    return <Radio.Button key={item.id} value={item.id}>{item.name}</Radio.Button>;
                                })
                            }
                        </Radio.Group> : <span style={{ lineHeight: '32px' }}></span>
                }
            </div>
            <div style={{ padding: '5px 0', borderBottom: '1px dashed #d9d9d9' }}>
                <span style={{ fontWeight: 'bold' }}>保险种类：</span>
                {
                    (typeData && typeData.length > 0) ?
                        <Radio.Group disabled={mode != 0} value={curentType?.id} onChange={(e) => {
                            var item = typeData.filter(d => d.id == e.target.value)[0];
                            setCurrentType(item)
                        }
                        } buttonStyle="solid">
                            {
                                typeData.map((item: any) => {
                                    return <Radio.Button key={item.id} value={item.id}>{item.name + '(费率' + item.rate + ')'}</Radio.Button>;
                                })
                            }
                        </Radio.Group> : <span style={{ lineHeight: '32px' }}></span>
                }
            </div>
            <Form ref={formRef} >
                <div style={{ padding: '5px 0', borderBottom: '1px dashed #d9d9d9' }}>
                    <span >
                        <span style={{ lineHeight: '30px', fontWeight: 'bold' }}>保险信息：</span>
                        <Space>
                            <a onClick={() => { setContent({ title: '投保申明', info: notice }); setOpen(true) }}>《投保申明》</a>
                            <a onClick={() => { setContent({ title: '投保须知', info: disclaimer }); setOpen(true) }}>《投保须知》</a>
                        </Space>
                    </span>
                    <span >
                        <div style={{ margin: '10px' }}>
                            <span style={{ width: '70px', display: 'block', float: "left", lineHeight: '30px' }}>投保类型：</span>
                            <FormItem noStyle name={'insureType'} initialValue={insureType.value}>
                                <Select disabled={mode != 0}
                                    labelInValue
                                    defaultValue={insureType.value}
                                    onSelect={(e) => { console.log(e); setInsureType(e) }}
                                    style={{ width: '230px', marginRight: '8px' }}
                                    options={[
                                        { value: 0, label: '个人投保个人' },
                                        { value: 1, label: '企业投保企业' },
                                        { value: 2, label: '个人投保企业' },
                                        { value: 3, label: '企业投保个人' },
                                    ]} />
                            </FormItem>
                            <FormItem noStyle name="isSame" initialValue={isSame} >
                                <Checkbox disabled={mode != 0} checked={isSame} onChange={(e) => {
                                    setIsSame(e.target.checked);
                                    if (e.target.checked) {
                                        var value = formRef.current?.getFieldsValue();
                                        formRef.current?.setFieldsValue({
                                            insuredBy: value.insureBy,
                                            insuredID: value.insureID,
                                            insuredTel: value.insureTel,
                                        })
                                        setValidRecord(Object.assign({}, validRecord, {
                                            insuredBy: (value.insureBy != '' ? true : false),
                                            insuredID: (value.insureID != '' ? true : false)
                                        }));
                                    }
                                }}>被保险人和投保人相同</Checkbox>
                            </FormItem>
                        </div>
                        <div style={{ margin: '10px' }}>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>投保人：</span>
                                <FormItem noStyle name='insureBy' initialValue={''}>
                                    <Search disabled={mode != 0} status={validRecord && validRecord.insureBy ? '' : 'error'}
                                        onChange={(e) => {
                                            if (isSame) {
                                                formRef.current?.setFieldValue('insuredBy', e.target.value);
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insureBy: (e.target.value != '' ? true : false),
                                                    insuredBy: (e.target.value != '' ? true : false)
                                                }));
                                            } else {
                                                setValidRecord(Object.assign({}, validRecord, { insureBy: (e.target.value != '' ? true : false) }));
                                            }
                                        }} style={{ width: '230px', float: "left", marginRight: '8px' }}
                                        enterButton={<ContactsOutlined />}
                                        onSearch={() => {
                                            setSelectType(0);
                                            setOpenSelect(true);
                                        }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>
                                    {(insureType.value == 1 || insureType.value == 3) ? '信用代码' : '身份证号'}：
                                </span>
                                <FormItem noStyle name='insureID' initialValue={''}>
                                    <Input disabled={mode != 0}
                                        status={!curentType?.isIDRequired || (validRecord && validRecord.insureID) ? '' : 'error'}
                                        onChange={(e) => {
                                            if (isSame) {
                                                formRef.current?.setFieldValue('insuredID', e.target.value);
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insureID: (!curentType?.isIDRequired || e.target.value.length == 18 ? true : false),
                                                    insuredID: (!curentType?.isIDRequired || e.target.value.length == 18 ? true : false)
                                                }));
                                            } else {
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insureID: (!curentType?.isIDRequired || e.target.value.length == 18 ? true : false)
                                                }));
                                            }
                                        }} style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>手机号：</span>
                                <FormItem noStyle name={'insureTel'} initialValue={''}>
                                    <Input disabled={mode != 0} status={validRecord && validRecord.insureTel ? '' : 'error'}
                                        onChange={(e) => {
                                            if (isSame) {
                                                formRef.current?.setFieldValue('insuredTel', e.target.value);
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insureTel: (e.target.value.length == 11 ? true : false),
                                                    insuredTel: (e.target.value.length == 11 ? true : false)
                                                }));
                                            } else {
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insureTel: (e.target.value.length == 11 ? true : false)
                                                }));
                                            }
                                        }}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                        </div>
                        <div style={{ margin: '10px' }}>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>被保人：</span>
                                <FormItem noStyle name={'insuredBy'} initialValue={''}>
                                    <Search status={validRecord && validRecord.insuredBy ? '' : 'error'}
                                        onChange={(e) => {
                                            if (isSame && mode == 0) {
                                                formRef.current?.setFieldValue('insureBy', e.target.value ?? '');
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insureBy: (e.target.value != '' ? true : false),
                                                    insuredBy: (e.target.value != '' ? true : false)
                                                }));
                                            } else {
                                                setValidRecord(Object.assign({}, validRecord
                                                    , { insuredBy: (e.target.value != '' ? true : false) }));
                                            }
                                        }} style={{ width: '230px', float: "left", marginRight: '8px' }}
                                        enterButton={<ContactsOutlined />}
                                        onSearch={() => {
                                            setSelectType(1);
                                            setOpenSelect(true);
                                        }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>
                                    {(insureType.value == 1 || insureType.value == 2) ? '信用代码' : '身份证号'}：
                                </span>
                                <FormItem noStyle name={'insuredID'} initialValue={''}>
                                    <Input
                                        status={!curentType?.isIDRequired || (validRecord && validRecord.insuredID) ? '' : 'error'}
                                        onChange={(e) => {
                                            if (isSame && mode == 0) {
                                                formRef.current?.setFieldValue('insureID', e.target.value);
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insureID: (!curentType?.isIDRequired || e.target.value.length == 18 ? true : false),
                                                    insuredID: (!curentType?.isIDRequired || e.target.value.length == 18 ? true : false)
                                                }));
                                            } else {
                                                setValidRecord(Object.assign({}, validRecord, {
                                                    insuredID: (!curentType?.isIDRequired || e.target.value.length == 18 ? true : false)
                                                }));
                                            }
                                        }} style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>手机号：</span>
                                <FormItem noStyle name={'insuredTel'} initialValue={''}>
                                    <Input onChange={(e) => {
                                        if (isSame && mode == 0) {
                                            formRef.current?.setFieldValue('insureTel', e.target.value);
                                            setValidRecord(Object.assign({}, validRecord, {
                                                insureTel: (e.target.value.length == 11 ? true : false),
                                            }));
                                        }
                                    }} style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                        </div>
                        <div style={{ margin: '10px' }}>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>起运日期：</span>
                                <FormItem noStyle name={'departureTime'} initialValue={moment()}>
                                    <DatePicker status={validRecord && validRecord?.departureTime ? '' : 'error'}
                                        disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                        onChange={(e) =>
                                            setValidRecord(Object.assign({}, validRecord, {
                                                departureTime: (e != null ? true : false)
                                            }))
                                        }
                                        defaultValue={moment()}
                                        format="YYYY-MM-DD"
                                        style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>起运地：</span>
                                <FormItem noStyle name={'startAddress'} initialValue={''}>
                                    <Input status={validRecord && validRecord.startAddress ? '' : 'error'}
                                        onChange={(e) => { setValidRecord(Object.assign({}, validRecord, { startAddress: (e.target.value != '' ? true : false) })) }}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>目的地：</span>
                                <FormItem noStyle name={'destAddress'} initialValue={''}>
                                    <Input status={validRecord && validRecord.destAddress ? '' : 'error'}
                                        onChange={(e) => { setValidRecord(Object.assign({}, validRecord, { destAddress: (e.target.value != '' ? true : false) })) }}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>中转地：</span>
                                <FormItem noStyle name={'transitAddress'} initialValue={''}>
                                    <Input style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                        </div>
                        <div style={{ margin: '10px' }}>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>货物品名：</span>
                                <FormItem noStyle name={'partName'} initialValue={''}>
                                    <Input status={validRecord && validRecord.partName ? '' : 'error'}
                                        onChange={(e) => { setValidRecord(Object.assign({}, validRecord, { partName: (e.target.value != '' ? true : false) })) }}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>货物包装：</span>
                                <FormItem noStyle name={'package'} initialValue={''}>
                                    <Select status={validRecord && validRecord.package ? '' : 'error'}
                                        labelInValue
                                        onSelect={(e) => { setValidRecord(Object.assign({}, validRecord, { package: (e ? true : false) })) }}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }}
                                        options={[
                                            { value: '散装及托盘包装', label: '散装及托盘包装' },
                                            { value: '捆包状包装', label: '捆包状包装' },
                                            { value: '袋状包装', label: '袋状包装' },
                                            { value: '箱状包装', label: '箱状包装' },
                                            { value: '桶状包装', label: '桶状包装' },
                                            { value: '裸状包装', label: '裸状包装' },
                                            { value: '其他形状包装', label: '其他形状包装' }
                                        ]} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>货物数量：</span>
                                <FormItem noStyle name={'eaQty'} initialValue={''}>
                                    <Input status={validRecord && validRecord.eaQty ? '' : 'error'}
                                        onChange={(e) => { setValidRecord(Object.assign({}, validRecord, { eaQty: (e.target.value != '' ? true : false) })) }}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>车牌号：</span>
                                <FormItem noStyle name={'truckNO'} initialValue={''}>
                                    <Input status={validRecord && validRecord.truckNO ? '' : 'error'}
                                        onChange={(e) => { setValidRecord(Object.assign({}, validRecord, { truckNO: (e.target.value != '' ? true : false) })) }}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                        </div>
                        <div style={{ margin: '10px' }}>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>运输方式：</span>
                                <FormItem noStyle name={'transportMode'} initialValue={transportMode.value}>
                                    <Select
                                        defaultValue={transportMode.value}
                                        value={transportMode.value}
                                        labelInValue
                                        onSelect={(e) => setTransportMode(e)}
                                        style={{ width: '230px', float: "left", marginRight: '8px' }}
                                        options={[
                                            { value: '0', label: '公路' },
                                            { value: '1', label: '水运' },
                                            { value: '2', label: '航空' },
                                            { value: '3', label: '铁路' },
                                        ]} />

                                </FormItem>
                            </span>
                            <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                                <span style={{ width: '70px', display: 'block', float: "left" }}>运单编号：</span>
                                <FormItem noStyle name={'transportNO'} initialValue={''}>
                                    <Input style={{ width: '230px', float: "left", marginRight: '8px' }} />
                                </FormItem>
                            </span>
                        </div>
                    </span>
                </div>
                <div style={{ padding: '5px 0', borderBottom: '1px dashed #d9d9d9' }}>
                    <span style={{ lineHeight: '30px', fontWeight: 'bold' }}>费用信息：</span>
                    <div style={{ margin: '10px' }}>
                        <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                            <span style={{ width: '70px', display: 'block', float: "left" }}>货值：</span>
                            <FormItem noStyle name={'partValue'} initialValue={'1'}>
                                <InputNumber disabled={mode != 0}
                                    status={validRecord && validRecord.partValue ? '' : 'error'}
                                    onChange={(e) => {
                                        setValidRecord(Object.assign({}, validRecord, { partValue: (e != null ? true : false) }));
                                        caculateAmount();
                                    }}
                                    style={{ width: '230px', float: "left", marginRight: '8px' }}
                                    min={1} max={curentType?.limitsAmount > 0 ? curentType?.limitsAmount : 300}
                                    defaultValue={1}
                                    addonBefore={"限额" + (curentType?.limitsAmount > 0 ? curentType?.limitsAmount : 300)}
                                    addonAfter="(万元)" step="1" />
                            </FormItem>
                        </span>
                        <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                            <span style={{ width: '70px', display: 'block', float: "left" }}>x 费率：</span>
                            <FormItem noStyle name={'rate'} initialValue={0}>
                                <InputNumber style={{ width: '230px', float: "left", marginRight: '8px' }} readOnly addonBefore="万分之" />
                            </FormItem>
                        </span>
                        <span style={{ paddingRight: '5px', lineHeight: '30px' }}>
                            <span style={{ width: '70px', display: 'block', float: "left" }}> = 保费：</span>
                            <FormItem noStyle name={'amount'} initialValue={0}>
                                <InputNumber readOnly min={curentType?.minAmount ?? 15} defaultValue={0}
                                    addonBefore={'最低' + (curentType?.minAmount ?? 15)}
                                    addonAfter="(元)" status="error"
                                    step="0.01" style={{ width: '230px', float: "left", marginRight: '8px' }} />
                            </FormItem>
                        </span>
                    </div>
                </div>
            </Form>
            <div style={{ textAlign: 'center', padding: '10px', display: mode == 0 ? 'block' : 'none' }}><Checkbox checked={isRead} onChange={onReadChange}>已阅读<a>《保险条款》</a></Checkbox></div>
            <div style={{ textAlign: 'center', padding: '10px', display: mode == 0 ? 'block' : 'none' }}><Button type='primary' disabled={!isRead} onClick={onSubumit}>立即投保</Button></div>
            <div style={{ textAlign: 'center', padding: '10px', display: mode == 1 ? 'block' : 'none' }}><Button type='primary' onClick={onUpdate}>提交修改</Button></div>
            <Drawer title={content.title} size={'large'} placement="right" onClose={onClose} open={open}>
                <TextArea style={{ height: '100%', width: '100%' }} bordered={false} readOnly value={content?.info} />
            </Drawer>
            <InsureInfo isOpen={openSelect}
                insureType={insureType}
                setIsOpen={(value: any) => setOpenSelect(value)}
                selectType={selectType}
                setValue={(value: any) => {
                    switch (selectType) {
                        case 0:
                            formRef.current?.setFieldValue('insureBy', value.name);
                            formRef.current?.setFieldValue('insureID', value.id);
                            formRef.current?.setFieldValue('insureTel', value.tel != '' ? value.tel : userInfo.tel);
                            setValidRecord(Object.assign({}, validRecord, {
                                insureBy: (value.name != '' ? true : false),
                                insureID: (value.id != '' ? true : false),
                                insureTel: (value.Tel != '' ? true : false),
                            }));
                            if (isSame) {
                                formRef.current?.setFieldValue('insuredBy', value.name);
                                formRef.current?.setFieldValue('insuredID', value.id);
                                formRef.current?.setFieldValue('insuredTel', value.tel != '' ? value.tel : userInfo.tel);
                                setValidRecord(Object.assign({}, validRecord, {
                                    insureBy: (value.name != '' ? true : false),
                                    insureID: (value.id != '' ? true : false),
                                    insureTel: (value.Tel != '' ? true : false),
                                    insuredBy: (value.name != '' ? true : false),
                                    insuredID: (value.id != '' ? true : false),
                                    insuredTel: (value.Tel != '' ? true : false),
                                }));
                            }
                            break;
                        case 1:
                            formRef.current?.setFieldValue('insuredBy', value.name);
                            formRef.current?.setFieldValue('insuredID', value.id);
                            formRef.current?.setFieldValue('insuredTel', value.tel != '' ? value.tel : userInfo.tel);
                            setValidRecord(Object.assign({}, validRecord, {
                                insuredBy: (value.name != '' ? true : false),
                                insuredID: (value.id != '' ? true : false),
                                insuredTel: (value.Tel != '' ? true : false),
                            }));
                            if (isSame) {
                                formRef.current?.setFieldValue('insureBy', value.name);
                                formRef.current?.setFieldValue('insureID', value.id);
                                formRef.current?.setFieldValue('insureTel', value.tel != '' ? value.tel : userInfo.tel);
                                setValidRecord(Object.assign({}, validRecord, {
                                    insuredBy: (value.name != '' ? true : false),
                                    insuredID: (value.id != '' ? true : false),
                                    insuredTel: (value.Tel != '' ? true : false),
                                    insureBy: (value.name != '' ? true : false),
                                    insureID: (value.id != '' ? true : false),
                                    insureTel: (value.Tel != '' ? true : false),
                                }));
                            }
                            break;
                    }
                }} />
        </div>
        <div style={{ display: currentStep == 1 ? 'block' : 'none', height: '100%' }}>
            <Row style={{ height: '100%', padding: '10px' }}>
                <Col span={order?.payStateEnum == 1 ? 24 : 12} >
                    <Descriptions title="保单信息" layout="horizontal" bordered size="small"
                        column={2}
                        extra={<div>
                            <Space size={2}>
                                <Button key="buy" danger onClick={() => {
                                    var url = '/admin/insure?oid=' + orderID + '&&isCopy=true';
                                    window.location.href = url;
                                }}>再来一单</Button>
                                {order?.stateEnum == 0 || (order?.stateEnum <= 2 && curentType?.isAllowUpdate) ? <Button onClick={() => {
                                    formRef.current?.setFieldsValue(order);
                                    setValidRecord({
                                        insureBy: true,
                                        insureID: true,
                                        insureTel: true,
                                        insuredBy: true,
                                        insuredID: true,
                                        insuredTel: true,
                                        departureTime: true,
                                        startAddress: true,
                                        destAddress: true,
                                        transitAddress: true,
                                        partName: true,
                                        package: true,
                                        eaQty: true,
                                        truckNO: true,
                                        partValue: true
                                    });
                                    setMode(1);
                                    loadOrder(orderID);
                                    setCurrentStep(0);
                                }}>修改保单</Button> : ''}
                                <Button type='primary' onClick={() => {
                                    setContent({ title: '保险条款', info: clauses });
                                    setOpen(true)
                                }}>查看条款</Button>
                                <Button type='dashed' onClick={() => window.location.href = '/admin/order'}>保单管理</Button>
                                {order?.stateEnum >= 2 && order?.insuranceUrl && order?.insuranceUrl != '' ? <Button type='dashed' onClick={() => {
                                    if (order.isOpenDown) {
                                        window.open(order.insuranceUrl)
                                    } else {
                                        downloadFile(order.orderID, order.insuranceNO + ".pdf");
                                    }
                                }} >下载保单</Button> : ''}
                            </Space>
                        </div>}>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="投保单号" >{order?.orderNO}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="状态" >
                            {
                                order?.stateEnum == 0 ? <Badge status={'default'} text={order?.state} /> :
                                    (order?.stateEnum == 1 ? <Badge status={'warning'} text={order?.state} /> :
                                        (order?.stateEnum == 2 ? <Badge status={'processing'} text={order?.state} /> :
                                            (order?.stateEnum == 3 ? <Badge status={'success'} text={order?.state} /> :
                                                (order?.stateEnum == 4 ? <Badge status={'warning'} text={order?.state} /> :
                                                    (order?.stateEnum == 5 ? <Badge status={'error'} text={order?.state} /> :
                                                        (order?.stateEnum == 6 ? <Badge status={'error'} text={order?.state} /> :
                                                            (order?.stateEnum == 7 ? <Badge status={'success'} text={order?.state} /> :
                                                                (order?.stateEnum == 8 ? <Badge status={'success'} text={order?.state} /> :
                                                                    (order?.stateEnum == 9 ? <Badge status={'default'} text={order?.state} /> : '')))))))))
                            }
                        </Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="保单号">{order?.insuranceNO}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="起保时间">{order && order?.insureTime ? moment(order?.insureTime).format('YYYY-MM-DD HH:mm:ss') : ''}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="保险范围">{order?.scope}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="保险公司">{order?.companyName}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="保险产品">{order?.productType}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="保险种类">{order?.type + '(费率' + order?.rate + ')'}</Descriptions.Item>
                        {/* <Descriptions.Item labelStyle={{ width: '100px' }} > */}
                        <Descriptions.Item labelStyle={{ width: '100px' }} span={3} label="投保类型">{order?.insureType}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="投保人">{order?.insureBy}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="手机号">{order?.insureTel}</Descriptions.Item>
                        <Descriptions.Item span={2} labelStyle={{ width: '100px' }} label={order?.insureTypeEnum == 1 || order?.insureTypeEnum == 3 ? '信用代码' : '身份证'}>{order?.insureID}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="被保人">{order?.insureType}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="手机号">{order?.insuredTel}</Descriptions.Item>
                        <Descriptions.Item span={2} labelStyle={{ width: '100px' }} label={order?.insureTypeEnum == 1 || order?.insureTypeEnum == 2 ? '信用代码' : '身份证'}>{order?.insuredID}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="起运日期">{order && order?.departureTime ? moment(order?.departureTime).format('YYYY-MM-DD') : ''}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="运输方式">{order?.transportMode}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="起运地">{order?.startAddress}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="目的地">{order?.destAddress}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="中转地">{order?.transitAddress}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="运单号">{order?.transportNO}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="货物品名">{order?.partName}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="包装">{order?.package}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="数量">{order?.eaQty}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="车牌号">{order?.truckNO}</Descriptions.Item>
                        {/* </Descriptions.Item> */}
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="货值">{order?.partValue}(万元)</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ width: '100px' }} label="保费">{order?.amount}(元)</Descriptions.Item>
                        <Descriptions.Item label="支付状态:" >{order?.payState}</Descriptions.Item>
                        <Descriptions.Item label="支付金额:" >{order?.payAmount}(元)</Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col style={{ display: order?.payStateEnum == 1 ? 'none' : 'block' }} span={2} ></Col>
                <Col style={{ display: order?.payStateEnum == 1 ? 'none' : 'block' }} span={8} >
                    <div style={{ padding: '8px' }}>
                        <Descriptions title="支付信息" labelStyle={{ width: '100px' }} layout="horizontal" bordered size="small"
                            column={1}>
                            <Descriptions.Item label="支付状态:" >{order?.payState}</Descriptions.Item>
                            <Descriptions.Item label="账户余额:" >{balance}元</Descriptions.Item>
                            <Descriptions.Item label="保费金额:">{order?.amount}元</Descriptions.Item>
                            <Descriptions.Item label="扣款金额:">{order?.amount}元</Descriptions.Item>
                        </Descriptions>
                        <div style={{ textAlign: 'center', margin: '5px' }}>
                            <Button style={{ margin: '5px' }} type='primary' onClick={onPay}>立即支付</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div style={{ display: currentStep == 2 ? 'block' : 'none' }}>
            <Result
                style={{ marginTop: '50px' }}
                status="success"
                title="投保成功"
                subTitle={"投保成功，保单号：" + order?.insuranceNO + ".誉旋科技为您开启售后服务，尽情期待！！！"}
                extra={[
                    <Button type="primary" key="console" onClick={() => window.location.href = '/admin/order'}>保单管理</Button>,
                    <Button onClick={() => { window.location.href = '/admin/insure?mode=2&&oid=' + orderID }}>查看保单</Button>,
                    <Button key="buy" danger onClick={() => {
                        var url = '/admin/insure?oid=' + orderID + '&&isCopy=true';
                        window.location.href = url;
                    }}>再来一单</Button>
                ]}
            />
        </div>
    </div >
}
export default Insure;