import { Button, Modal, Input, message, Space, Table } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import InfoEdit from "./InfoEdit";
const { confirm } = Modal;

const Info = () => {
    const [page, setPage] = useState({ pageIndex: 1, pageSize: 10 });
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any>([])
    const [searchValue, setSearchValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const columns: ColumnsType<any> = [
        { key: '0', title: '类型', dataIndex: 'type', align: 'center' },
        { key: '1', title: '个人/企业', dataIndex: 'name', align: 'center' },
        { key: '2', title: '身份证/营业执照', dataIndex: 'id', align: 'center' },
        { key: '4', title: '联系电话', dataIndex: 'tel', align: 'center' }
        , {
            title: '操作', align: 'center', width: 60, fixed: 'right', render: (record: any) => (
                <Space size="small">
                    <Button size="small" onClick={() => {
                        setEditRecord({
                            viewMode: 1,
                            insureInfoID: record.insureInfoID
                        });
                        setIsOpen(true)
                    }}>编辑</Button>
                    <Button type='primary' onClick={() => { showDeleteConfirm(record) }} danger size="small">删除</Button>
                </Space>
            )
        }]

    useEffect(() => {
        loadData();
    }, [])


    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize,
        };
        Server.Post(URL.InsureInfo.QueryInsureInfoList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除投/被保人',
            icon: <ExclamationCircleFilled />,
            content: '投/被保人[' + record.name + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { insureInfoID: record.insureInfoID }
                Server.Post(URL.InsureInfo.DeleteInsureInfo, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };

    useEffect(() => {
        loadData();
    }, [page])

    return <div style={{ height: '100%' }}>
        <div style={{ margin: '5px' }}>
            <Input allowClear onChange={(e) => setSearchValue(e.target.value)} style={{ width: '450px', marginRight: '10px' }}
                placeholder="请输入名称/ID/电话模糊匹配查询" />
            <span style={{ float: 'right' }}>
                <Button type='primary' onClick={loadData}>查询</Button>
            </span>
        </div>
        <div style={{ margin: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>投/被保列表</span>
                <Space style={{ float: 'right' }}>
                    <Button onClick={() => {
                        setEditRecord({
                            viewMode: 0,
                            insureInfoID: null
                        });
                        setIsOpen(true)
                    }}>新增</Button>
                </Space>
            </div>
            <Table size="small"
                bordered
                scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { marginRight: '10px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} />
        </div>
        <InfoEdit isOpen={isOpen} setIsOpen={(value: any) => {
            setIsOpen(value);
            loadData();
        }} {...editRecord} />
    </div>
}
export default Info;