import { Button, Form, FormInstance, Input, InputNumber, message, Modal, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import React from "react";
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";

const Recharge = (props: any) => {
    const formRef = React.useRef<FormInstance>(null);
    const { ...rest } = props;
    const [balance, setBalance] = useState(0);
    const [memberBalance, setMemberBalance] = useState(0);

    useEffect(() => {
        if (rest.isOpen) {
            var params = {};
            Server.Post(URL.Balance.QueryBalance, params).then((response: any) => {
                if (response.isSuccess) {
                    setBalance(response.balance);
                }
            })

            params = {memberID : rest.member.memberID};
            Server.Post(URL.Balance.QueryBalance, params).then((response: any) => {
                if (response.isSuccess) {
                    setMemberBalance(response.balance);
                }
            })
        }
    }, [rest.isOpen])

    const handleOk = () => {
        formRef.current?.validateFields(['amount'])
            .then((values) => {
                if (values.amount <= 0) {
                    message.warn('充值金额必须大于0');
                    return;
                }
                var params = {
                    memberID: rest.member.memberID,
                    amount: values.amount
                };
                Server.Post(URL.Agent.AgentRecharge, params).then((response: any) => {
                    if (response.isSuccess) {
                        rest.setIsOpen(false);
                        message.success('充值成功')
                        rest.reload();
                    }
                });
            })
    }
    const handleCancel = () => {
        rest.setIsOpen(false);
    }
    return <Modal width={400} title="代理充值" open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        footer={
            <Space>
                <Button size='middle' onClick={handleCancel}>关闭窗口</Button>
                <Button type='primary' onClick={handleOk} danger size='middle'>立即充值</Button>
            </Space>
        }>
        <Form ref={formRef} size='middle'>
            <FormItem label={'我的余额'}>
                <span style={{ width: '100%' }}>{balance}(元)</span>
            </FormItem>
            <FormItem label={'充值会员'}>
                <span style={{ width: '100%' }}>{rest.member?.account}</span>
            </FormItem>
            <FormItem label={'会员余额'}>
                <span style={{ width: '100%' }}>{memberBalance}(元)</span>
            </FormItem>
            <FormItem label={'充值金额'} initialValue={0} name={'amount'} >
                <InputNumber max={balance} style={{ width: '100%' }} min={0} addonAfter="(元)" status="error"
                    step="0.01" />
            </FormItem>
        </Form>
    </Modal>
}
export default Recharge;