import { Input, Tabs, TabsProps, DatePicker, Button, Table, Pagination } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";

const { RangePicker } = DatePicker;

const Balance = () => {
    const [page, setPage] = useState({ pageIndex: 1, pageSize: 20 });
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any>([]);
    const [page1, setPage1] = useState({ pageIndex: 1, pageSize: 20 });
    const [total1, setTotal1] = useState(0);
    const [dataSource1, setDataSource1] = useState<any>([]);
    const [balance, setBalance] = useState(0);
    const [activeKey, setActiveKey] = useState('0');
    const [searchValue, setSearchValue] = useState('');
    const [timeValue, setTimeValue] = useState<any>([null, null]);
    const dateFormat = 'YYYY-MM-DD';

    const columns: ColumnsType<any> = [
        { key: 'transactionNO', title: '交易单号', dataIndex: 'transactionNO', align: 'center', fixed: 'left' },
        {
            key: 'payTime', title: '支付时间', dataIndex: 'payTime', align: 'center',
            render: (text, record) => (
                text ? moment(text).format('YYYY-MM-DD hh:mm:ss') : ''
            )
        },
        { key: 'payAmount', title: '支付金额（元）', dataIndex: 'payAmount', align: 'center' },
        { key: 'payMode', title: '支付方式', dataIndex: 'payMode', align: 'center' },
        { key: 'member', title: '支付会员账号', dataIndex: 'member', align: 'center' },
        { key: 'amount', title: '充值金额（元）', dataIndex: 'amount', align: 'center' },
        { key: 'balance', title: '账户余额（元）', dataIndex: 'balance', align: 'center' }]

    const columns1: ColumnsType<any> = [
        { key: 'transactionNO', title: '交易单号', dataIndex: 'transactionNO', align: 'center' },
        {
            key: 'payTime', title: '支付时间', dataIndex: 'payTime', align: 'center',
            render: (text, record) => (
                text ? moment(text).format('YYYY-MM-DD hh:mm:ss') : ''
            )
        },
        { key: 'payContent', title: '支付内容', dataIndex: 'payContent', align: 'center' },
        { key: 'amount', title: '账单金额（元）', dataIndex: 'amount', align: 'center' },
        { key: 'payAmount', title: '支付金额（元）', dataIndex: 'payAmount', align: 'center' },
        { key: 'payMode', title: '支付方式', dataIndex: 'payMode', align: 'center' },
        { key: 'balance', title: '账户余额', dataIndex: 'balance', align: 'center' },
        {
            key: 'insuranceNO', title: '保单号', dataIndex: 'insuranceNO', align: 'center',
        }]

    useEffect(() => {
    }, [])

    const loadRecharge = () => {
        var params = {
            searchValue: searchValue,
            startTime: timeValue && timeValue[0] ? moment(timeValue[0]).format(dateFormat) : null,
            endTime: timeValue && timeValue[1] ? moment(timeValue[1]).format(dateFormat) : null,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Balance.QueryRechargeList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    useEffect(() => {
        loadRecharge();
    }, [page])

    const loadConsumption = () => {
        var params = {
            searchValue: searchValue,
            startTime: timeValue && timeValue[0] ? moment(timeValue[0]).format(dateFormat) : null,
            endTime: timeValue && timeValue[1] ? moment(timeValue[1]).format(dateFormat) : null,
            pageIndex: page1.pageIndex,
            pageSize: page1.pageSize
        };
        Server.Post(URL.Balance.QueryConsumptionList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource1(response.data);
                setTotal1(response.total);
            }
        });
    }

    useEffect(() => {
        loadConsumption();
    }, [page1])

    const loadBalance = () => {
        var params = {};
        Server.Post(URL.Balance.QueryBalance, params, false).then((response: any) => {
            if (response.isSuccess) {
                setBalance(response.balance ?? 100);
            }
        });
    }

    useEffect(() => {
        if (activeKey == '0') {
            loadBalance();
        }
    }, [activeKey])

    const items: TabsProps['items'] = [
        {
            key: '0', label: '充值记录',
            children: <div>
                <div style={{ marginTop: '5px' }}>
                    <Input allowClear onChange={(e) => setSearchValue(e.target.value)} style={{ width: '450px', marginRight: '10px' }} placeholder="请输入交易单号" />
                    <RangePicker allowClear format={dateFormat} onChange={(value) => setTimeValue(value)} style={{ marginRight: '10px' }} placeholder={['支付时间', '至']} />
                    <span style={{ float: 'right' }}>
                        <span style={{ fontWeight: 'bold', color: 'red', marginRight: '10px' }}>账户余额(元)： {balance} 　　</span>
                        <Button type='primary' onClick={loadRecharge}>查询</Button>
                    </span>
                </div>
                <div style={{ marginTop: '5px', border: '1px solid #d9d9d9' }}>
                    <Table size="small" scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                        position: ['bottomRight'],
                        style: { marginRight: '10px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 20,
                        pageSize: page.pageSize,
                        pageSizeOptions: [20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }} />
                </div>
            </div>
        },
        {
            key: '1', label: '消费记录',
            children: <div>
                <div style={{ marginTop: '5px' }}>
                    <Input allowClear onChange={(e) => setSearchValue(e.target.value)} style={{ width: '450px', marginRight: '10px' }} placeholder="请输入交易单号" />
                    <RangePicker allowClear format={dateFormat} onChange={(value) => setTimeValue(value)} style={{ marginRight: '10px' }} placeholder={['支付时间', '至']} />
                    <span style={{ float: 'right' }}>
                        <Button type='primary' onClick={loadConsumption} >查询</Button>
                    </span>
                </div>
                <div style={{ marginTop: '5px', border: '1px solid #d9d9d9' }}>
                    <Table size="small" scroll={{ x: 'max-content' }} columns={columns1} dataSource={dataSource1}
                        pagination={{
                            position: ['bottomRight'],
                            style: { marginRight: '10px' },
                            showSizeChanger: true,
                            showQuickJumper: true,
                            total: total1,
                            showTotal: ((total) => {
                                return "共" + total + "条";
                            }),
                            current: page1.pageIndex,
                            defaultCurrent: 20,
                            pageSize: page1.pageSize,
                            pageSizeOptions: [20, 50, 100, 200],
                            onShowSizeChange: ((page, pageSize) => {
                                setPage1({
                                    pageIndex: page,
                                    pageSize: pageSize
                                });
                            }),
                            onChange: ((page, pageSize) => {
                                setPage1({
                                    pageIndex: page,
                                    pageSize: pageSize
                                });
                            })
                        }} />
                </div>
            </div>
        }
    ]

    return <div>
        <Tabs items={items} defaultValue={activeKey} onChange={(key: string) => { setActiveKey(key) }} />
    </div>
}
export default Balance;